import apigClientFactory from 'aws-api-gateway-client';
import { apiGatewayClient } from './apiConfig';

export const getSearchData = async (requestParams) => {
  const apigClient = apigClientFactory.newClient(apiGatewayClient);
  const additionalParams = {
    headers: {
      'Strict-Transport-Security':
        'max-age=63072000; includeSubDomains; preload',
      'Content-Security-Policy': "default-src 'self'",
    },
    queryParams: {
      q: `"${requestParams}"`,
      size: 10000,
    },
  };
  const response = apigClient.invokeApi({}, '/', 'GET', additionalParams);
  return response;
};
